var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("HeadImage", {
        attrs: {
          src: _vm.product["image_url"],
          contain: "",
          action: _vm.recentAction,
          "map-zone": _vm.product["map_zone"],
        },
      }),
      _c("div", { staticClass: "c-section c-section--mb32" }, [
        _c("h1", { staticClass: "c-title4" }, [
          _vm._v(" " + _vm._s(_vm.product["name"]) + " "),
        ]),
        _c("p", { staticClass: "c-lead" }, [
          _vm._v(" " + _vm._s(_vm.product["description"]) + " "),
        ]),
        _vm.product["ec_link_url"]
          ? _c("p", { staticClass: "c-lead" }, [
              _c(
                "a",
                {
                  staticClass: "c-textButton c-textButton--right",
                  attrs: {
                    href: _vm.$customUrlScheme.page(
                      "ec",
                      "url=" + _vm.product["ec_link_url"]
                    ),
                  },
                },
                [
                  _c("Icon", {
                    staticClass:
                      "c-textButton__icon c-textButton__icon--prepend",
                    attrs: { name: "cartOff" },
                  }),
                  _vm._v(" オンラインストアで購入 "),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c("MemoForm", {
        attrs: { memo: _vm.memo, editable: _vm.editable },
        on: {
          toggle: _vm.toggleFormType,
          update: function ($event) {
            return _vm.$emit("update", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }