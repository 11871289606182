<template>
  <div>
    <HeadImage
      :src="product['image_url']"
      contain
      :action="recentAction"
      :map-zone="product['map_zone']" />

    <div class="c-section c-section--mb32">
      <h1 class="c-title4">
        {{ product['name'] }}
      </h1>

      <p class="c-lead">
        {{ product['description'] }}
      </p>

      <p v-if="product['ec_link_url']" class="c-lead">
        <a
          :href="$customUrlScheme.page('ec', `url=${product['ec_link_url']}`)"
          class="c-textButton c-textButton--right">
          <Icon
            name="cartOff"
            class="c-textButton__icon c-textButton__icon--prepend" />
          オンラインストアで購入
        </a>
      </p>
    </div>
    <!-- /.c-section -->

    <MemoForm
      :memo="memo"
      :editable="editable"
      @toggle="toggleFormType"
      @update="$emit('update', $event)" />
  </div>
</template>

<script>
export default {
  components: {
    MemoForm: () => import('@/components/coffeeHistoryDetail/MemoForm.vue')
  },

  props: {
    impression: {
      type: Object,
      required: true
    },

    product: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    editable: false
  }),

  computed: {
    /**
     * @returns {{ [key: string]: number | null; }}
     */
    memo() {
      const memo = this.impression.memo;
      return {
        ...memo,
        aroma: memo?.aroma || null,
        sweetness: memo?.sweetness || null,
        sourness: memo?.sourness || null,
        bitterness: memo?.bitterness || null,
        richness: memo?.richness || null
      };
    },

    recentAction() {
      if (!this.impression) return;

      const at = this.impression.created_at;
      const isFavorable = this.impression.is_favorable;
      const type =
        isFavorable === null ? 'memo' : isFavorable ? 'like' : 'hate';

      return { type, at };
    }
  },

  methods: {
    toggleFormType() {
      this.editable = !this.editable;
    }
  }
};
</script>
